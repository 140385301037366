import React, { useState, useEffect, useRef } from 'react';
import { Preloader } from '../Components/Preloader';
import * as PE from '../utils/PEAPI';

import ReactGA from 'react-ga';


import HeaderImage from '../static/header-community.jpg';


const Merch = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [merchList, setMerchList] = useState();
    const [searchTerm, setSearchTerm] = useState();
    const [searchLocked, setSearchLocked] = useState(false);
    const ref = useRef(null);

    const handleScroll = () => {
        if (ref.current){
            setSearchLocked(ref.current.getBoundingClientRect().top <= 0);
        }
    }

    const listMerch = (items) => {
        if(items){
            const listItems = items.map( item => 
                
                /*item.subMerch.length > 0 ? //if the item has a DLC
                <li className="merchContainerWithSubMerch"
                    key={item.gameTitle}
                    title={item.gameTitle}
                >
                    <div className="merchThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.merchURL} title = {item.merchName}>
                            <img className="merchThumb" src={item.merchThumb} alt={item.merchName} />
                            <h2>{item.merchName}</h2>
                        </a>
                    </div>

                    <div className="subMerchContainer">
                       <h3>Related Items</h3>
                        <ul className="subMerch">
                            {item.subMerch.map( subMerch => 
                            <li key={subMerch.subMerchName+'-sub'}>
                                <a rel="noreferrer" target="_blank" href={subMerch.subMerchURL} title = {subMerch.subMerchName}>
                                    <img className="subMerchThumb" src={subMerch.subMerchThumb} alt={subMerch.subMerchName} />
                                    <h2>{subMerch.subMerchName}</h2>
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                                  
                </li>
                : //if the item doesn't have a DLC*/
                <li className="merchContainer"
                    key={item.merchName}
                    title={item.merchName}
                >
                    <div className="merchThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.merchURL} title = {item.merchName} onClick = {() => {ReactGA.event({category: 'Merch', action: 'click', label: item.merchName});}}>
                            <img className="merchThumb" src={item.merchThumb} alt={item.merchName} />
                            <h2>{item.merchName}</h2>
                        </a>
                    </div>
                </li>
            )
            return(
                <>
                    {listItems}
                </>
            )
        } else {
            return;
        }
    }

    useEffect( () => {
        window.scrollTo(0, 0);
        document.title = "PartyElite | Merchandise";

        if (window.location.origin.includes('localhost')){
        } else {
            const currentPath = window.location.pathname + window.location.search;
            ReactGA.pageview(currentPath);
        }

        PE.getMerch()
        .then(response => {
            setMerchList(response.data.body);
            setIsLoaded(true);
        })
        
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
    },[])

    
    if(!isLoaded){
        return( <>
            <div id="featured">
                <div id="featured-image-container">
                    <img className="featured-image" src={ HeaderImage } alt="header"/>
                </div>
                <div id="featured-details">
                    <h3>Party has wares</h3>
                    <h1 className="title">Merch Store</h1>
                    <p className="subtitle">Check out some channel merch! All through Teespring for now - click through and you'll find shirts, hoodies, stickers, mugs and more for each design.</p>
                </div>
            </div>
            <Preloader/>
            </>
        )
    } else {

        return(
            <>
                <div id="featured">
                    <div id="featured-image-container">
                        <img className="featured-image" src={ HeaderImage } alt="header"/>
                    </div>
                    <div id="featured-details">
                        <h3>Party has wares</h3>
                        <h1 className="title">Merch Store</h1>
                    <p className="subtitle">Check out some channel merch! All through Teespring for now - click through and you'll find shirts, hoodies, stickers, mugs and more for each design.</p>
                    </div>
                </div>
                
                <div id="merch">    
                    <div
                        ref = {ref}>
                    <input
                        type="text"
                        name="search"
                        className={ "big-search " + (searchLocked ? 'fixed' : '') }
                        placeholder="Search by name, description, and more!"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    </div>
                    <div id="merchList"> 
                        {searchTerm ?
                            listMerch(merchList.filter(merch => JSON.stringify(merch).toLowerCase().match(searchTerm.toLowerCase())))
                        :
                            listMerch(merchList)
                        }
                       
                    </div>

                </div>
            
            </>
        )
    }
}

export default Merch;