import React, { useState, useEffect } from 'react';
import { useMousePosition } from '../Components/UseMousePosition'
import { FeaturedButton } from '../Components/Button';
import { Preloader } from '../Components/Preloader';
import * as YouTube from '../utils/YouTubeAPI';
import * as PE from '../utils/PEAPI';

import ReactGA from 'react-ga';


 var scrollHover;
 var mousePos;
 var mx;
        
 var mPadd = 60, // mousemove Padding
     damp = 20, // Mmusemove response softness
     mX = 0, // real mouse position
     mX2 = 0, // modified mouse position
     posX = 0;

const Home = () => {


    const [isLoaded, setIsLoaded] = useState();

    const [featuredVideos, setFeaturedVideos] = useState();
    const [latestVideos, setLatestVideos] = useState();
    const [opVideos, setOpVideos] = useState();
    const [lpVideos, setLpVideos] = useState();

    const [headerData, setHeaderData] = useState();
    
    mousePos = useMousePosition();

    const getData = async() => {
        await PE.getHeaderVideos()
        .then(response => {
            const newHeaderData = {};
            newHeaderData.title = response.data[0].displayTitle;
            newHeaderData.subtitle = response.data[0].videoSubtitle;
            newHeaderData.descr = response.data[0].videoDescr;
            newHeaderData.img = response.data[0].headerImg;
            newHeaderData.link = response.data[0].videoLink;

            setHeaderData(newHeaderData);
        })
                
        await PE.getLPVideos()
        .then(response => {
            setLpVideos(response.data);
        })       

        await YouTube.getPlaylist('UUg3BiSs8eBE9hA9EWYTgtAg', '14')
        .then(response => {
            setLatestVideos(response.data.items)
        })
        
        await YouTube.getPlaylist('PLwVftHdWAdHPdpTnGn-nbvFbhmfoXtmVc', '100')
        .then(response2 => {
            setOpVideos(response2.data.items)
        })

        await YouTube.getPlaylist('PLwVftHdWAdHPmY-7NQcnAZZuvDIvyWYpw', '100')
        .then(response3 => {
            setFeaturedVideos(response3.data.items)
        })
    }
    

    useEffect( () => {
        window.scrollTo(0, 0);
        document.title = "PartyElite | Official Website";
        
        if (window.location.origin.includes('localhost')){
        } else {
            const currentPath = window.location.pathname + window.location.search;
            ReactGA.pageview(currentPath);
        }
            
            getData()
            .then( res => {
               setIsLoaded(true)
            });

    },[])

/*    const handleHover = (e) => {
        const row = e.target.closest('.vidRow');
        const rowWidth = row.offsetWidth;
        console.log(mousePos.x);

        if (mx > rowWidth/2){
            scrollHover = window.setInterval(() => {
                row.scrollLeft += 1; 
                mx = mousePos.x;
            }, 10);
            console.log(scrollHover);

        } else {
            scrollHover =  window.setInterval(() => {
                row.scrollLeft -= 1; 
                mx = mousePos.x;
            }, 10);
        }
    }

    const handleHover = (e) => {
       // console.log(e.target.closest('.vidRow').offsetWidth);
        //console.log('e.scrollWidth', e.target.scrollWidth)
        const row = e.target.closest('.vidRow');
        const  rowWidth = e.target.closest('.vidRow').offsetWidth,
             rowScrollWidth = e.target.closest('.vidRow').scrollWidth,
             mmAA = rowWidth - (mPadd * 2),
             mmAAr = (rowWidth / mmAA),
             wDiff = (rowScrollWidth/rowWidth) - 1;

            mX = mousePos.x - row.closest('div').offsetLeft - e.target.offsetLeft;
            mX2 = Math.min(Math.max(0, mX - mPadd), mmAA) * mmAAr;
            
            
            scrollHover = setInterval(function() {
                posX += (mX2 - posX) / damp; // zeno's paradox equation "catching delay"	
                row.scrollLeft = posX * wDiff; 
            }, 10);
            console.log(mX2, posX)

    }
*/
    const endHover = (e) => {
        window.clearInterval(scrollHover);
        console.log(scrollHover);
    }

    const listVideos = (items) => {
        if(items==lpVideos){ //featuredVideos are handled differently to allow customization of featured section
            if(items){
                const listItems = items.map((item, index) =>
                    <li className="vidThumbContainer"
                        key={index}
                        alt={item.videoTitle}
                        title={item.videoTitle}
                        data-url={`https://www.youtube.com/watch?v=${item.videoId}&list=${item.playlistId}`}
                        data-thumbnail={`https://i.ytimg.com/vi/NBOreVx6Z1M/maxresdefault.jpg`}>
                        <a target="blank" rel="noreferrer" title={item.videoTitle} href={`https://www.youtube.com/watch?v=${item.videoId}&list=${item.playlistId}`} onClick = {() => {ReactGA.event({category: 'Video', action: 'click', label: item.videoTitle});}}>
                            <img className="vidTallThumb" src={item.videoImg} alt={item.videoTitle}/>
                        </a>                    
                    </li>
                )
                return (
                    <>
                        {listItems}
                    </>
                )
            } else {
                return;
            }
        }
        else{
            const listItems = items.map((item, index) =>
                <li className="vidThumbContainer"
                    key={index}
                    title={item.snippet.title}
                    data-url={'https://www.youtube.com/watch?v=' + item.contentDetails.videoId}
                    data-thumbnail={item.snippet.thumbnails.medium.url}>
                    <a target="blank" rel="noreferrer" title={item.snippet.title} href={'https://www.youtube.com/watch?v=' + item.contentDetails.videoId} onClick = {() => {ReactGA.event({category: 'Video', action: 'click', label: item.snippet.title});}}>
                    <img className="vidThumb" src={item.snippet.thumbnails.medium.url} alt={item.snippet.title} />
                    </a>
                </li>
            )
            return (
                <>
                    {listItems}
                </>
            )
        }
    }

    if(!isLoaded){
        return(
            <>
            <div id="page" className="home">
            </div>
            <div className="home">
            <Preloader/>
            </div>

         </>
        )
    } else {

        return(
            <div id="page" className="loaded">
                <div id="featured">
                    <div id="featured-image-container">
                        <img className="featured-image" src={headerData.img} alt={headerData.title} title={headerData.title}/>
                    </div>
                    <div id="featured-details">
                        <h3>Featured Video</h3>
                        <h1 className="title">{headerData.title}</h1>
                        <p className="subtitle">{headerData.subtitle}</p>
                        <FeaturedButton 
                            class="btn-play filled icon-play"
                            text="Watch Now" 
                            link={headerData.link}
                        />
                        <p className="descr">{headerData.descr}</p>
                    </div>
            </div>
            
            <h3>More Featured Videos</h3>
            <div className="rowShadow"></div>
            <ul
                className="vidRow"
                id="latest"
            >                    
                {listVideos(featuredVideos)}
            </ul>

            <h3>Latest</h3>
            <div className="rowShadow"></div>
            <ul
                className="vidRow"
                id="latest"
            >
                {listVideos(latestVideos)}
            </ul>

            <h3>Reviews, Previews and Opinions</h3>
            <div className="rowShadow"></div>
            <ul
                className = "vidRow"
                id = "opinion"
            >
                {listVideos(opVideos)}
            </ul>

            <h3>Popular Let's Plays</h3>
            <div className="rowShadow"></div>
            <ul
                className = "vidRow"
                id = "opinion"
            >
                {listVideos(lpVideos)}
            </ul>
                <div id="and-more">
                    <h2>And there's much more to watch <a target="_blank" rel="noreferrer" title="YouTube Channel - PartyElite" href="https://youtube.com/c/PartyElite">on the channel</a>.</h2>
                </div>
            </div>
            
        )
    }
}

export default Home;