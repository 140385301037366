import React, { useEffect } from 'react';
import { FeaturedButton } from '../Components/Button';
import { Card } from '../Components/Card';

import ReactGA from 'react-ga';

import HeaderImage from '../static/header-community.jpg';



const Contact = () => {

    useEffect( () => {
        window.scrollTo(0, 0);
        document.title = "PartyElite | Contact";

        if (window.location.origin.includes('localhost')){
        } else {
            const currentPath = window.location.pathname + window.location.search;
            ReactGA.pageview(currentPath);
        }
    },[])
    
    return(
        <div id="content">
            <div id="featured">
                <div id="featured-image-container">
                    <img className="featured-image" src={ HeaderImage } alt="header"/>
                </div>
                <div id="featured-details">
                    <h3>Let's talk</h3>
                    <h1 className="title">Contact Me</h1>
                    <p className="subtitle">at <a href="mailto:contact@partyelite.gg">contact@partyelite.gg</a> for all business opportunities - game coverage, sponsorsed content, and more.</p>
                    <FeaturedButton 
                        class="btn-play filled icon-mail"
                        text="Email me" 
                        link="mailto:contact@partyelite.gg"
                    />
                    <FeaturedButton 
                        class="btn-play filled icon-twitter"
                        text="Follow Me" 
                        link="https://twitter.com/_PartyElite"
                    />
                </div>
          </div>
        
          <div className = "card-container">
            <Card 
                width = "half-width"
                title = "I play games & tell stories"
                body = "Focussed primarily around strategy, tactics, and roleplaying, my coverage of games includes everything from reviews, previews, let's plays, live streams, guide videos and more."
                btns = ""/>
            <Card 
                width = "half-width"
                title = "PO Box"
                body = ""
                btns = "">
                    Want to send me something?<br/>Send it here (and feel free to email me a heads up)!<br/><br/>
                    PO BOX 65 Toronto F
                    <br/>Toronto ON M4Y 2L4      
            </Card>
          
          </div>

        </div>

    )
}

export default Contact;