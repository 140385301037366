import axios from 'axios';
const KEY = "AIzaSyBZX5vZ-78HlFfhb2BSeNs6Pnuidqgq4KI";

export const YouTubeAPI = axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3/',
    params: {
        key: KEY
    }
})


export const getPlaylist = async(id, results) => {
    let response = await YouTubeAPI.get('/playlistItems', {
        params: {
            playlistId: id,
            part: 'snippet,contentDetails',
            channelId: 'UCg3BiSs8eBE9hA9EWYTgtAg',
            maxResults: results
        }
    });

//    console.log(response);
    return response;
}


export const getMembersList = async(id, results) => {
    let response = await YouTubeAPI.get('/members', {
        params: {
            part: 'snippet'
        }
    });

   console.log(response);
    return response;
}