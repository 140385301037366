import React, { useState, useEffect } from 'react';
import * as YouTube from '../utils/YouTubeAPI';
import * as PE from '../utils/PEAPI';
import { RoundActionBtn } from '../Components/Button'


import HeaderImage from '../static/header-community.jpg';

const Admin = () => {

    const [tab, setTab] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const [featuredVideos, setFeaturedVideos] = useState();
    const [latestVideos, setLatestVideos] = useState();
    const [opVideos, setOpVideos] = useState();
    const [lpVideos, setLpVideos] = useState();
    const [initialLpVideos, setInitialLpVideos] = useState();

    const [addingVideo, setAddingVideo] = useState(false);
    const [isTopFeatured, setIsTopFeatured] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isLetsPlay, setIsLetsPlay] = useState(false);
    const [newVideoTitle, setNewVideoTitle] = useState();
    const [newVideoDisplayTitle, setNewVideoDisplayTitle] = useState();
    const [newVideoHeaderImage, setNewVideoHeaderImage] = useState();
    const [newVideoSubtitle, setNewVideoSubtitle] = useState();
    const [newVideoDescr, setNewVideoDescr] = useState();
    const [newVideoPlaylistId, setNewVideoPlaylistId] = useState();
    const [newVideoId, setNewVideoId] = useState();


    const [gamesList, setGamesList] = useState();
    const [initialGamesList, setInitialGamesList] = useState();
    const [addingGame, setAddingGame] = useState(false);
    const [newGameTitle, setNewGameTitle] = useState('');
    const [newGameURL, setNewGameURL] = useState('');
    const [newGameThumb, setNewGameThumb] = useState('');
    const [newDLCParent, setNewDLCParent] = useState('');

    const [merchList, setMerchList] = useState();
    const [initialMerchList, setInitialMerchList] = useState();
    const [addingMerch, setAddingMerch] = useState(false);
    const [newMerchOrder, setNewMerchOrder] = useState('');
    const [newMerchName, setNewMerchName] = useState('');
    const [newMerchURL, setNewMerchURL] = useState('');
    const [newMerchThumb, setNewMerchThumb] = useState('');
    const [newMerchParent, setNewMerchParent] = useState('');
    const [newMerchCategory, setNewMerchCategory] = useState('');
    const [newMerchTags, setNewMerchTags] = useState('');

    const getData = async() => {
        PE.getGames()
        .then(response => {
            setGamesList(response.data.body);
            setInitialGamesList(response.data.body);
        })

        PE.getMerch()
        .then(response => {
            setMerchList(response.data.body);
            setInitialMerchList(response.data.body);
        })

        PE.getLPVideos()
        .then(response => {
            setLpVideos(response.data);
            setInitialLpVideos(response.data);
        })
        //YouTube.getMembersList()
    }

    //handle tab switching using numbers to set tab
    const handleTabClick = e => {
        const i = parseInt (e.target.id, 0);
        
        if (i !== tab){
            setTab(i)
        }
    }

    const removeThis = (list, itemTitle) => {
        if (list == gamesList){
            setGamesList(gamesList.filter((item) => item.gameTitle !== itemTitle))
        }
        if (list == merchList){
            setMerchList(merchList.filter((item) => item.merchName !== itemTitle))
            console.log(list, itemTitle)
        }
    }

    

    const saveList = (e) => {
        if (e == gamesList){

            initialGamesList.map((item, index) => {
                if(!gamesList.some(game => new RegExp(item.gameTitle, "i").test(game.gameTitle))){
                    PE.deleteGame(item)
                } else {
                    PE.putGames(item)
                }
            })

            gamesList.map((item, index) => {
                PE.putGames(item);
            })
        }
        if (e == merchList){
            console.log(merchList);
            initialMerchList.map(item => {
                console.log(item.merchName)
                if(!merchList.some(merch => new RegExp(item.merchName, "i").test(merch.merchName))){
                    PE.deleteMerch(item)
                } else {
                    PE.putMerch(item)
                }
            })

            merchList.map(item => {
                PE.putMerch(item)
            })
        }
    }

    const handleNewVideoSubmit = (e) => {
        e.preventDefault();
        
        const newVideo = {};

        newVideo.videoTitle = newVideoTitle;
        newVideo.videoId = newVideoId;
        newVideo.videoLink = `https://www.youtube.com/watch?v=${newVideoId}`
        newVideo.videoThumb = `https://i.ytimg.com/vi/${newVideoId}/maxresdefault.jpg`

        if(isTopFeatured){
            newVideo.isTopFeatured = true;
            newVideo.displayTitle = newVideoDisplayTitle;
            newVideo.subtitle = newVideoSubtitle;
            newVideo.description = newVideoDescr;
            newVideo.headerImg = newVideoHeaderImage;
            newVideo.playlistId = newVideoPlaylistId;
        } else if(isFeatured) {
            newVideo.isFeatured = true;
            
        } else if(isLetsPlay) {
            newVideo.isLetsPlay = true;
            newVideo.videoLink = `https://www.youtube.com/watch?v=${newVideoId}&list=${newVideoPlaylistId}`
            newVideo.videoThumb = newVideoHeaderImage;
            console.log(lpVideos);
            setLpVideos(lpVideos.concat(newVideo))
        }

    }

    const handleNewGameSubmit = (e) => {
        e.preventDefault();

        if(!newDLCParent){
            const newGame = {};
            newGame.gameTitle = newGameTitle;
            newGame.gameThumb = newGameThumb;
            newGame.gameURL = newGameURL;
            newGame.DLC = [];

            setGamesList(gamesList.concat(newGame));
        } else {
            const newDLC = {};
            newDLC.dlcTitle = newGameTitle;
            newDLC.dlcThumb = newGameThumb;
            newDLC.dlcURL = newGameURL;

            const isParent = (e) => e.gameTitle === newDLCParent;
            const parentIndex = gamesList.findIndex(isParent);
            if (gamesList.[parentIndex].DLC){
                gamesList.[parentIndex].DLC.push(newDLC)
            } else {
                const DLC = [];
                newDLC.dlcTitle = newGameTitle;
                newDLC.dlcThumb = newGameThumb;
                newDLC.dlcURL = newGameURL;

                DLC.push(newDLC);
                gamesList.[parentIndex].push(DLC);
            }
        }
        
        setNewGameTitle('');
        setNewGameURL('');
        setNewGameThumb('');
        setNewDLCParent('');
    }

    const handleNewMerchSubmit = (e) => {
        e.preventDefault();

        if(!newMerchParent){
            const newMerch = {};
            newMerch.order = newMerchOrder;
            newMerch.merchName = newMerchName;
            newMerch.merchCategory = newMerchCategory;
            newMerch.merchTags = newMerchTags;
            newMerch.merchURL = newMerchURL;
            newMerch.merchThumb = newMerchThumb;
            newMerch.subMerch = [];

            setMerchList(merchList.concat(newMerch));
        } else {
            const newSubMerch = {};
            newSubMerch.subMerchOrder = newMerchOrder;
            newSubMerch.subMerchName = newMerchName;
            newSubMerch.subMerchThumb = newMerchThumb;
            newSubMerch.subMerchURL = newMerchURL;

            const isParent = (e) => e.merchName === newDLCParent;
            const parentIndex = merchList.findIndex(isParent);
            if (merchList.[parentIndex].subMerch){
                merchList.[parentIndex].subMerch.push(newSubMerch)
            } else {
                const subMerch = [];
                newSubMerch.subMerchOrder = newMerchOrder;
                newSubMerch.subMerchName = newMerchName;
                newSubMerch.subMerchThumb = newMerchThumb;
                newSubMerch.subMerchURL = newMerchURL;

                subMerch.push(newSubMerch);
                merchList.[parentIndex].push(subMerch);
            }
        }
    }

    const listVideosAdmin = (items) => {
        if(items==lpVideos){ //LPVideos are handled differently to allow customization of featured section
            if(items){
                const listItems = items.map((item, index) =>
                    <li className="vidThumbContainer"
                        key={index}
                        alt={item.videoTitle}
                        title={item.videoTitle}
                        data-url={'https://www.youtube.com/watch?v=' + item.videoId}
                        data-thumbnail={item.videoThumb}>
                        <img className="vidThumb" src={item.videoThumb} alt={item.videoTitle}/>
                        
                        <RoundActionBtn
                            type = "remove"
                            onClick = {removeThis}
                        />

                    </li>
                )
                return (
                    <>
                        {listItems}
                    </>
                )
            } else {
                return;
            }
        }
        else{
            const listItems = items.map((item, index) =>
                <li className="vidThumbContainer"
                    key={index}
                    title={item.snippet.title}
                    data-url={'https://www.youtube.com/watch?v=' + item.contentDetails.videoId}
                    data-thumbnail={item.snippet.thumbnails.medium.url}>
                    <img className="vidThumb" src={item.snippet.thumbnails.medium.url} alt={item.snippet.title} />
                </li>
            )
            return (
                <>
                    {listItems}
                </>
            )
        }
    }

    const listGamesAdmin = (items) => {
        if(items){
            const listItems = items.map( item => 
                
                item.DLC.length > 0 ? //if the item has a DLC
                <li className="gameContainerWithDLC"
                    key={item.gameTitle}
                    title={item.gameTitle}
                >
                    <div className="gameThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.gameURL} title = {item.gameTitle}>
                            <img className="gameThumb" src={item.gameThumb} alt={item.gameTitle} />
                            <h2>{item.gameTitle}</h2>
                        </a>
                    </div>

                    <div className="gameDLCContainer">
                       <h3>{item.gameTitle} DLC & Related Items</h3>
                        <ul className="gameDLC">
                            {item.DLC.map( DLC => 
                            <li key={DLC.dlcTitle}>
                                <a rel="noreferrer" target="_blank" href={DLC.gameURL} title = {DLC.dlcTitle}>
                                    <img className="DLCThumb" src={DLC.dlcThumb} alt={DLC.dlcTitle} />
                                    <h2>{DLC.dlcTitle}</h2>
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                                    
                    <RoundActionBtn
                        type = "remove"
                        onClick = {() => removeThis(gamesList, item.gameTitle)} 
                    />
                </li>
                : //if the item doesn't have a DLC
                <li className="gameContainer"
                    key={item.gameTitle}
                    title={item.gameTitle}
                >
                    <div className="gameThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.gameURL} title = {item.gameTitle}>
                            <img className="gameThumb" src={item.gameThumb} alt={item.gameTitle} />
                            <h2>{item.gameTitle}</h2>
                        </a>
                                    
                        <RoundActionBtn
                            type = "remove"
                            onClick = {() => removeThis(gamesList, item.gameTitle)} 
                        />
                    </div>
                </li>
            )
            return(
                <>
                    {listItems}
                </>
            )
        } else {
            return;
        }
    }
    const listMerchAdmin = (items) => {
        if(items){
            const listItems = items.map( item => 
                
                item.subMerch.length > 0 ? //if the item has sub merch
                <li className="merchContainerWithSubMerch"
                    key={item.merchName}
                    title={item.merchName}
                >
                    <div className="merchThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.merchURL} title = {item.merchName}>
                            <img className="merchThumb" src={item.merchThumb} alt={item.merchName} />
                            <h2>{item.merchName}</h2>
                        </a>
                    </div>

                    <div className="subMerchContainer">
                       <h3>Related Items</h3>
                        <ul className="subMerch">
                            {item.subMerch.map( subMerch => 
                            <li key={subMerch.subMerchName+'-sub'}>
                                <a rel="noreferrer" target="_blank" href={subMerch.subMerchURL} title = {subMerch.subMerchName}>
                                    <img className="subMerchThumb" src={subMerch.subMerchThumb} alt={subMerch.subMerchName} />
                                    <h2>{subMerch.subMerchName}</h2>
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                                    
                    <RoundActionBtn
                        type = "remove"
                        onClick = {() => removeThis(merchList, item.merchName)} 
                    />
                </li>
                : //if the item doesn't have sub merch
                <li className="merchContainer"
                    key={item.merchName}
                    title={item.merchName}
                >
                    <div className="merchThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.merchURL} title = {item.merchName}>
                            <img className="merchThumb" src={item.merchThumb} alt={item.merchName} />
                            <h2>{item.merchName}</h2>
                        </a>
                                    
                        <RoundActionBtn
                            type = "remove"
                            onClick = {() => removeThis(merchList, item.merchName)} 
                        />
                    </div>
                </li>
            )
            return(
                <>
                    {listItems}
                </>
            )
        } else {
            return;
        }
    }

    useEffect( () => {

        getData();

        YouTube.getPlaylist('UUg3BiSs8eBE9hA9EWYTgtAg', '14')
        .then(response => {
            setLatestVideos(response.data.items)
        })
            .then( () => {      
                YouTube.getPlaylist('PLwVftHdWAdHPdpTnGn-nbvFbhmfoXtmVc', '7')
                .then(response2 => {
                    setOpVideos(response2.data.items)
                })
                    YouTube.getPlaylist('PLwVftHdWAdHPmY-7NQcnAZZuvDIvyWYpw', '5')
                    .then(response3 => {
                        setFeaturedVideos(response3.data.items)
                    })
                        .then( () => {
                            setIsLoaded(true)
                        });
            })

    },[])


    if(!isLoaded){
        return(
            <p>loading</p>
        )
    } else {

        return(
            <>
                <div id="featured">
                    <div id="featured-image-container">
                        <img className="featured-image" src={ HeaderImage } alt="header"/>
                    </div>
                    <div id="featured-details">
                        <h1 className="title">Admin Page</h1>
                        <p className="subtitle">For admin stuff.</p>
                        <p 
                            className="btn-play filled icon-plus"
                            onClick={handleTabClick}
                            id={0}
                        >Videos</p>
                        <p 
                            className="btn-list filled icon-plus"
                            onClick={handleTabClick}
                            id={1}
                        >Games</p>
                        <p 
                            className="btn-list filled icon-plus"
                            onClick={handleTabClick}
                            id={2}
                        >Merch</p>
                    </div>
                </div>
                <div className={'adminTab' + (tab=='0' ? ' active' : '') } id="videos">
                    <h2>Videos</h2>
                    
                    <hr/>

                    <div id="vidsAddition">
                        <form
                            id="vidsAdditionForm"
                            className={addingVideo ? 'active' : ''}
                            onSubmit={handleNewVideoSubmit}
                            >
                            <input
                                type = "text"
                                name = "title"
                                placeholder = "Title"
                                value = {newVideoTitle}
                                onChange = { e => setNewVideoTitle(e.target.value)}
                            />
                            <input
                                type = "text"
                                name = "title"
                                placeholder = "Display Title (if Header Feature)"
                                className = {isTopFeatured ? 'visible' : 'hidden'}
                                value = {newVideoDisplayTitle}
                                onChange = { e => setNewVideoDisplayTitle(e.target.value)}
                            />
                            <p><input
                                type = "checkbox"
                                name = "topFeatured"
                                value = {isTopFeatured}
                                onClick = { () => setIsTopFeatured(!isTopFeatured)}
                            /> Header Feature 
                            
                            <input
                                type = "checkbox"
                                name = "featured"
                                value = {isFeatured}
                                onClick = { () => setIsFeatured(!isFeatured)}
                            /> Featured 

                            <input
                                type = "checkbox"
                                name = "topFeatured"
                                value = {isLetsPlay}
                                onClick = { () => setIsLetsPlay(!isLetsPlay)}
                            /> Let's Play 
                            
                            </p>
                            <input
                                type = "text"
                                name = "subtitle"
                                placeholder = "Subtitle (if Header Feature)"
                                className = {isTopFeatured ? 'visible' : 'hidden'}
                                value = { newVideoSubtitle }
                                onChange = { e => setNewVideoSubtitle(e.target.value)}
                            />
                            <input
                                type = "text"
                                name = "videoId"
                                placeholder = "Video ID"
                                value = { newVideoId }
                                onChange = { e => setNewVideoId(e.target.value)}
                            />
                            <input
                                type = "text"
                                name = "playlistId"
                                placeholder = "Playlist ID"
                                value = { newVideoPlaylistId }
                                onChange = { e => setNewVideoPlaylistId(e.target.value)}
                            />
                            <input
                                type = "textarea"
                                name = "descr"
                                placeholder = "Description (if Header Feature)"
                                className = {isTopFeatured ? 'visible' : 'hidden'}
                                value = { newVideoDescr }
                                onChange = { e => setNewVideoDescr(e.target.value)}
                            />
                            <input
                                type = "textarea"
                                name = "headerImage"
                                placeholder = "Header Image URL (if Header Feature)"
                                className = {isTopFeatured ? 'visible' : 'hidden'}
                                value = { newVideoHeaderImage }
                                onChange = { e => setNewVideoHeaderImage(e.target.value)}
                            />
                            <input
                                type = "textarea"
                                name = "lpImage"
                                placeholder = "Let's Play Image URL (if Let's Play)"
                                className = {isLetsPlay ? 'visible' : 'hidden'}
                                value = { newVideoHeaderImage }
                                onChange = { e => setNewVideoHeaderImage(e.target.value)}
                            />
                            <br/>
                            <input type="Submit" value="Submit"/>
                        </form>
                    </div>
                        
                    <RoundActionBtn
                        type = {(addingVideo ? 'remove' : 'add')}
                        onClick = {() => setAddingVideo(!addingVideo)}
                    />
                    
                    <RoundActionBtn
                        type = "save"
                        onClick = {() => saveList(gamesList)}
                    />

                    <h3>Featured</h3>
                    <div className="rowShadow"></div>
                    <ul
                        className="vidRow"
                        id="Featured">
                        {listVideosAdmin(featuredVideos)}
                    </ul>

                    <h3>Latest</h3>
                    <div className="rowShadow"></div>
                    <ul
                        className="vidRow"
                        id="latest"
                    >
                        {listVideosAdmin(latestVideos)}
                    </ul>

                    <h3>Reviews, Previews and Opinions</h3>
                    <div className="rowShadow"></div>
                    <ul
                        className="vidRow"
                        id="opinion"
                    >
                        {listVideosAdmin(opVideos)}
                    </ul>

                    <h3>Let's Plays</h3>
                    <div className="rowShadow"></div>
                    <ul
                        className="vidRow"
                        id="letsPlays"
                    >
                        {listVideosAdmin(lpVideos)}
                    </ul>
                </div>
                <div className={'adminTab' + (tab=='1' ? ' active' : '') } id="games">
                    <h2>Games</h2>
                    <hr/>
                    
                    <div id="gamesAddition">
                        <form
                            id="gamesAdditionForm"
                            className={(addingGame ? 'active': '')}
                            onSubmit={handleNewGameSubmit}
                        >
                            
                            <input
                                type="text"
                                id="gameTitle"
                                name="gameTitle"
                                placeholder="Game Title"
                                value={newGameTitle}
                                onChange={e => setNewGameTitle(e.target.value)}
                            />
                                                        
                            <input
                                type="text"
                                id="gameURL"
                                placeholder="Game URL"
                                value={newGameURL}
                                onChange={e => setNewGameURL(e.target.value)}
                            />
                            
                            <input
                                type="text"
                                id="gameThumb"
                                name="gameThumb"
                                placeholder="Game Thumbnail URL"
                                value={newGameThumb}
                                onChange={e => setNewGameThumb(e.target.value)}
                            />
                            
                            <input
                                type="text"
                                id="DLCParent"
                                name="DLCParent"
                                placeholder="DLC Parent Game Name"
                                value={newDLCParent}
                                onChange={e => setNewDLCParent(e.target.value)}
                            />
                            <br/>
                            <input type="Submit" value="Submit"/>

                        </form>

                        <RoundActionBtn
                            type = {(addingGame ? 'remove' : 'add')}
                            onClick = {() => setAddingGame(!addingGame)}
                        />
                            
                        <RoundActionBtn
                            type = "save"
                            onClick = {() => saveList(gamesList)}
                        />

                    </div>

                    <div id="gamesList"> 

                        {listGamesAdmin(gamesList)}
                    </div>

                </div>

                
                <div className={'adminTab' + (tab=='2' ? ' active' : '') } id="merch">
                    <h2>Merch</h2>
                    <hr/>
                    
                    <div id="merchAddition">
                        <form
                            id="merchsAdditionForm"
                            className={(addingMerch ? 'active': '')}
                            onSubmit={handleNewMerchSubmit}
                        >
                            <input
                                type = "text"
                                id = "merchOrder"
                                name = "merchOrder"
                                placeholder = "Merch Order"
                                value = { newMerchOrder }
                                onChange = { e => setNewMerchOrder(e.target.value) }
                            />

                            <input
                                type = "text"
                                id = "merchName"
                                name = "merchName"
                                placeholder = "Merch Name"
                                value = { newMerchName }
                                onChange = { e => setNewMerchName(e.target.value) }
                            />

                            <input
                                type = "text"
                                id = "merchCategory"
                                name = "merchCategory"
                                placeholder = "Merch Category"
                                value = { newMerchCategory }
                                onChange = { e => setNewMerchCategory(e.target.value) }
                            />

                            <input
                                type = "text"
                                id = "merchTags"
                                name = "merchTags"
                                placeholder = "Merch Tags"
                                value = { newMerchTags }
                                onChange = { e => setNewMerchTags(e.target.value) }
                            />
                                                        
                            <input
                                type = "text"
                                id = "merchURL"
                                placeholder = "Merch URL"
                                value = { newMerchURL }
                                onChange = { e => setNewMerchURL(e.target.value) }
                            />
                            
                            <input
                                type = "text"
                                id = "merchThumb"
                                name = "merchThumb"
                                placeholder = "Merch Thumbnail URL"
                                value = { newMerchThumb }
                                onChange = { e => setNewMerchThumb(e.target.value) }
                            />
                            
                            <input
                                type = "text"
                                id = "MerchParent"
                                name = "MerchParent"
                                placeholder = "Merch Parent Name"
                                value = { newMerchParent }
                                onChange={ e => setNewMerchParent(e.target.value) }
                            />
                            <br/>
                            <input type="Submit" value="Submit"/>
                        </form>

                        <RoundActionBtn
                            type = {(addingMerch ? 'remove' : 'add')}
                            onClick = {() => setAddingMerch(!addingMerch)}
                        />
                            
                        <RoundActionBtn
                            type = "save"
                            onClick = {() => saveList(merchList)}
                        />
                    </div>

                    <div id="merchList"> 

                        {listMerchAdmin(merchList)}
                    </div>

                </div>
            
            </>
        )
    }
}

export default Admin;