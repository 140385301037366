import React, { useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes.js';

import SaveIcon from '../../static/icon-save.svg';

export const HeaderButton = (props) => {
    const [hover, setHover] = useState(false);
    const [current] = useState(false);

    return(
        <li>
            <NavLink
                exact
                onMouseEnter = {() => setHover(true)}
                onMouseLeave = {() => setHover(false)}
                activeClassName="active"
                className = { (hover ? 'hover' : '') + (current ? ' current' : '')}
                to = { props.href }>
                    { props.name }
            </NavLink>
        </li>
    )
}

export const FeaturedButton = (props) => {

    if (!props.link){
        return(
            <a
                className = { props.class }>
                    { props.text }
            </a>
        )
    } else {
        return(
            <a
                href={ props.link }
                target={ props.target }
                rel="noreferrer"
                className = { props.class }>
                    { props.text }
            </a>
        )
    }
}

export const MyAccountBtn = (props) => {
    const [loggedIn, setLoggedIn] = useState( props.username );

    if(!loggedIn){
        return(
            <li>
                <a
                    id = "my-account-btn"
                    onClick = { props.onClick }>
                    Sign in using YouTube
                </a>   
            </li>  
        )
    } else {
        return(
            <li>
                <Link
                    id = "my-account-btn"
                    className = "logged-in"
                    to = { ROUTES.SIGNIN }>
                    { props.username }
                </Link>   
            </li> 
        )
    }

}

export const SearchBtn = (props) => {
    const [isActive, setActive] = useState(false);
    const [hover, setHover] = useState(false);
    const [value, setValue] = useState("");

    const searchBarRef = useRef(null);
    
    const showSearchBar = () => {
        setActive(true)
        searchBarRef.current.focus();
    }
    const hideSearchBar = () => {
        setActive(false)
    }

    return (
        <li>
            <Link
                id = "search-btn"
                className = { (hover ? 'hover' : '' ) + (isActive ? ' active' : '') }
                onClick = { showSearchBar }
                to = { ROUTES.SEARCH }>
                <input
                    ref = { searchBarRef }
                    onBlur = { hideSearchBar }
                    id = "search-bar"
                    name = "search-bar"
                    type = "text"
                    placeholder = "Search"
                    value = { value }/>
                    <span className="icon-search"></span>
            </Link>
        </li>
    )
}

export const RoundActionBtn = (props) => {
    if(props.type === 'add'){
        return(
            <span
                className="btn-round add"
                onClick = {props.onClick}
            >
                +
            </span>
        )
    } else if(props.type === "remove"){
        return(
            <span
                className="btn-round remove"
                onClick = {props.onClick}
            >
                -
            </span>
        )
    } else if(props.type === "save"){
        return(
            <span
                className = "btn-round save"
                onClick = {props.onClick}
            >
                <img src={SaveIcon} alt="save" title="save"/>
            </span>
        )
    }
}