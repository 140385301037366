import React, { useEffect } from 'react';
import { FeaturedButton } from '../Components/Button';
import { Card } from '../Components/Card';
import * as ROUTES from '../constants/routes.js';

import ReactGA from 'react-ga';

import HeaderImage from '../static/header-community.jpg';

const Community = () => {

  useEffect( () => {
      window.scrollTo(0, 0);
      document.title = "PartyElite | Community";

      if (window.location.origin.includes('localhost')){
      } else {
          const currentPath = window.location.pathname + window.location.search;
          ReactGA.pageview(currentPath);
      }
  },[])

    return(
      <div id="content">
          <div id="featured">
              <div id="featured-image-container">
                  <img className="featured-image" src={ HeaderImage } alt="header"/>
              </div>
              <div id="featured-details">
                  <h3>Join an extremely active</h3>
                  <h1 className="title">Discord Community</h1>
                  <p className="subtitle">With over 3,000 members constantly playing all sorts of games, and being all around great people.</p>
                  <FeaturedButton 
                      class="btn-play filled icon-discord"
                      text="Join our discord" 
                      link="https://discord.gg/6Tq3ut5"
                      target="_blank"
                  />
                  <FeaturedButton 
                      class="btn-play filled icon-twitter"
                      text="My Twitter" 
                      link="https://twitter.com/_PartyElite"
                      target="_blank"
                  />
              </div>
        </div>

        <h2>Want to support the channel?</h2>
        <div className = "card-container">
          <Card 
            width = "full-width"
            title = "Patreon or YouTube Member"
            body = "Becoming a Patron on Patreon or a Member through YouTube gives you access to a variety of perks, including a title in our Discord, a credit in every video, priority naming rights for units and characters in our games, and ways to participate in giveaways and community events. Patreon and Channel Memberships make a very big difference in the sustainability of a YouTube channel, and your support is greatly appreciated."
            btns = {[{title: "Become a Patron", link: "https://www.patreon.com/PartyElite", icon:"icon-plus", target:"_blank"}, {title: "Become a Member", link: "https://www.youtube.com/c/PartyElite/join", icon:"icon-plus", target:"_blank"}]}/>
          <Card 
            width = "half-width"
            title = "Merch Store"
            body = "Check out some of the merch! We’ve got shirts, hoodies, mugs and even clothes for your babies."
            btns = {[{title: "Shop Now", link: ROUTES.MERCH, icon:"icon-plus"}]}/>
          <Card 
            width = "half-width"
            title = "Game Store"
            body = "Planning on picking up a new game? Want to subscribe to Humble Monthly? Here are my affiliate links, and my personal game store!."
            btns = {[{title: "My Game Store", link: ROUTES.GAMESSTORE, icon:"icon-plus"}, {title: "Visit Humble Bundle", link: "https://www.humblebundle.com/store?partner=PartyElite", icon:"icon-plus", target:"_blank"}]}/>
        </div>
      </div>
    )
}

export default Community;