import React, { useEffect } from 'react';

const Humankindreview = () => {

    useEffect( () => {
        window.location.href = "https://www.youtube.com/watch?v=-zXJmjz9wGM";
    },[])
    

        return (
            <div>
            <h2>HK</h2>
            </div>
        );
}

export default Humankindreview;