import React from 'react';
import { HeaderButton } from '../Button';
import * as ROUTES from '../../constants/routes.js';

export const HeaderNavigation = () => {

    const openSignIn = () => {
        console.log('signin');
    }

    return(
        <nav>
            <HeaderButton
                name="Home"
                href={ROUTES.HOME}
            />
            <HeaderButton
                name="Community"
                href={ROUTES.COMMUNITY}
            />
            <HeaderButton
                name="Game Store"
                href={ROUTES.GAMESSTORE}
            />
            <HeaderButton
                name="Merch"
                href={ROUTES.MERCH}
            />
            <HeaderButton
                name="Contact"
                href={ROUTES.CONTACT}
            />
            <li>
                <a href="https://youtube.com/c/PartyElite" title="The Channel" target="_blank" rel="noreferrer">
                    Visit the Channel
                </a>
            </li>
        
        </nav>
    )
}