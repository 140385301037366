import axios from 'axios';

export const PEAPI = axios.create({
    baseURL: 'https://taxyy9xjuc.execute-api.us-east-2.amazonaws.com/default/'
})

export const getLPVideos = async() => {
    let response = await PEAPI.get('/videos/lp', {
    })

    return response;
}

export const getHeaderVideos = async() => {
    let response = await PEAPI.get('/videos/featured', {
    })

    return response;
}


export const getGames = async() => {
    let response = await PEAPI.get('/games', {
    });

    return response;
}

export const putGames = async(data) => {
    const Game = JSON.stringify(data);
    let response = await PEAPI.post('/games', {
        body: Game
    })
    return response;
}

export const deleteGame = async(data) => {
    const Game = JSON.stringify(data);
    let response = await PEAPI.delete('/games', {
        data: Game
    })
    return response;
}

export const getMerch = async() => {
    let response = await PEAPI.get('/merch', {
    });
    return response;
}

export const putMerch = async(data) => {
    const Merch = JSON.stringify(data);
    let response = await PEAPI.post('/merch', {
        body: Merch
    })
    return response;
}

export const deleteMerch = async(data) => {
    const Merch = JSON.stringify(data);
    let response = await PEAPI.delete('/merch', {
        data: Merch
    })
    return response;
}