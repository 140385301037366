import React from 'react';

export const Card = (props) => {

    const buttons = Object.keys(props.btns).map((item, i) => (
        <li className="btns" key={i} >
            <a rel="noreferrer" target={props.btns[i].target} className={ "filled " + props.btns[i].icon} href={props.btns[i].link}>
                {props.btns[i].title}
            </a>
        </li>
    ))


    return(
        <div className = {"card " + props.width}>
            <h4>{ props.title }</h4>
            <p>{ props.body }</p>
            <p>{ props.children }</p>
            <ul>
                {buttons}
            </ul>
        </div>
    )
}