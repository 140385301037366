export const LANDING = '/';
export const HOME = '/';
export const COMMUNITY = '/community';
export const GAMESSTORE = '/game-store';
export const MERCH = '/merch';
export const CONTACT = '/contact';
export const SIGNIN = '/sign-in';
export const SEARCH = '/search';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const HUMANKINDREVIEW = '/humankind-review';