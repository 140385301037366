import React, { useEffect, useState } from 'react';
import './style.scss';
import { Header } from './Components/Header';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from './constants/routes.js';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import Home from './pages/home.js';
import community from './pages/community.js';
import gamestore from './pages/game-store.js';
import merch from './pages/merch.js';
import contact from './pages/contact.js';
import admin from './pages/admin.js';
import humankindreview from './pages/humankind-review.js';

const history = createBrowserHistory();

if (window.location.origin.includes('localhost')){
} else {
ReactGA.initialize('UA-120613045-5');
}

const App = () => {

  
  const [loaded, setLoaded] = useState(false);

  useEffect( () => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);
    
  }, [window.location])

  return (
    <Router history={history}>
      <div 
        id="main"
        className = { ( !loaded ? '' : 'loaded') }
        >
        <Header>
          <link
              rel="preload"
              href="https://fonts.googleapis.com/css?family=Lato:400,700|Eczar:800|Yeseva+One&display=swap"
              as="font"
          />
        </Header>
        <div id="content">
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route path={ROUTES.COMMUNITY} component={community} />
            <Route path={ROUTES.GAMESSTORE} component={gamestore} />
            <Route path={ROUTES.MERCH} component={merch} />
            <Route path={ROUTES.CONTACT} component={contact} />
            <Route path={ROUTES.ADMIN} component={admin} />
            <Route path={ROUTES.HUMANKINDREVIEW} component={humankindreview} />
        </div>
      </div>
      <div id="footer">
        
      </div>
    </Router>
  );
}

export default App;
