import React, { useState, useEffect, useRef } from 'react';
import { Preloader } from '../Components/Preloader';
import * as PE from '../utils/PEAPI';

import ReactGA from 'react-ga';


import HeaderImage from '../static/header-community.jpg';


const GameStore = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [gamesList, setGamesList] = useState();
    const [searchTerm, setSearchTerm] = useState();
    const [searchLocked, setSearchLocked] = useState(false);
    const ref = useRef(null);

    const handleScroll = () => {
        if (ref.current){
            setSearchLocked(ref.current.getBoundingClientRect().top <= 0);
        }
    }

    const listGames = (items) => {
        if(items){
            const listItems = items.map( item => 
                
                item.DLC.length > 0 ? //if the item has a DLC
                <li className="gameContainerWithDLC"
                    key={item.gameTitle}
                    title={item.gameTitle}
                >
                    <div className="gameThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.gameURL} title = {item.gameTitle} onClick = {() => {ReactGA.event({category: 'Games Store', action: 'click', label: item.gameTitle});}}>
                            <img className="gameThumb" src={item.gameThumb} alt={item.gameTitle} />
                            <h2>{item.gameTitle}</h2>
                        </a>
                    </div>

                    <div className="gameDLCContainer">
                       <h3>{item.gameTitle} DLC & Related Items</h3>
                        <ul className="gameDLC">
                            {item.DLC.map( DLC => 
                            <li key={DLC.dlcTitle}>
                                <a rel="noreferrer" target="_blank" href={DLC.dlcURL} title = {DLC.dlcTitle} onClick = {() => {ReactGA.event({category: 'Games Store', action: 'click', label: DLC.dlcTitle});}}>
                                    <img className="DLCThumb" src={DLC.dlcThumb} alt={DLC.dlcTitle} />
                                    <h2>{DLC.dlcTitle}</h2>
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                                  
                </li>
                : //if the item doesn't have a DLC
                <li className="gameContainer"
                    key={item.gameTitle}
                    title={item.gameTitle}
                >
                    <div className="gameThumbContainer">
                        <a rel="noreferrer" target="_blank" href={item.gameURL} title = {item.gameTitle} onClick = {() => {ReactGA.event({category: 'Games Store', action: 'click', label: item.gameTitle});}}>
                            <img className="gameThumb" src={item.gameThumb} alt={item.gameTitle} />
                            <h2>{item.gameTitle}</h2>
                        </a>
                    </div>
                </li>
            )
            return(
                <>
                    {listItems}
                </>
            )
        } else {
            return;
        }
    }

    useEffect( () => {
        window.scrollTo(0, 0);
        document.title = "PartyElite | Game Store";

        if (window.location.origin.includes('localhost')){
        } else {
            const currentPath = window.location.pathname + window.location.search;
            ReactGA.pageview(currentPath);
        }

        PE.getGames()
        .then(response => {
            setGamesList(response.data.body);
            setIsLoaded(true);
        })
        
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
    },[])

    
    if(!isLoaded){
        return( <>
            <div id="featured">
                <div id="featured-image-container">
                    <img className="featured-image" src={ HeaderImage } alt="header"/>
                </div>
                <div id="featured-details">
                    <h3>Grab your future favourites</h3>
                    <h1 className="title">Game<br/>Store</h1>
                    <p className="subtitle">Buy your games here to support the channel,<br/>and you'll get your keys through nexus.gg or Humble Bundle.</p>
                </div>
            </div>
            <Preloader/>
            </>
        )
    } else {

        return(
            <>
                <div id="featured">
                    <div id="featured-image-container">
                        <img className="featured-image" src={ HeaderImage } alt="header"/>
                    </div>
                    <div id="featured-details">
                        <h3>Grab your future favourites</h3>
                        <h1 className="title">Game<br/>Store</h1>
                        <p className="subtitle">Buy your games here to support the channel,<br/>and you'll get a Steam key through nexus.gg.</p>
                    </div>
                </div>
                
                <div id="games">    
                    <div
                        ref = {ref}>
                    <input
                        type="text"
                        name="search"
                        className={ "big-search " + (searchLocked ? 'fixed' : '') }
                        placeholder="Search by title"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    </div>
                    <div id="gamesList"> 
                        {searchTerm ?
                            listGames(gamesList.filter(game => game.gameTitle.toLowerCase().match(searchTerm.toLowerCase())))
                        :
                            listGames(gamesList)
                        }
                       
                    </div>

                </div>
            
            </>
        )
    }
}

export default GameStore;