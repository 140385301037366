import React from 'react';
import logo from '../../logo.svg';
import { HeaderNavigation } from '../Navigation';

export const Header = () => {

    return(
        <header>
            <img id="logo" src={logo} alt="PartyElite" title="PartyElite"/>
            <HeaderNavigation/>
        </header>
    )
}